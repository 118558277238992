<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/agent.png" alt />
        <span class="center">权限管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">资源管理-11</span>
      </div>
      <div style="display: flex; align-items: center">
        <span class="seach_text">关键字:</span>
        <el-input
          placeholder="请输入功能名称"
          v-model="keywords"
          @input="selectobjectName()"
          size="mini"
          style="margin-right: 10px; width: 250px"
          prefix-icon="el-icon-search"
          clearable
        ></el-input>
        <span class="seach_text" style="margin-left:15px">所属模块:</span>
        <el-select
          v-model="objectName"
          placeholder="选择所属模块"
          clearable
          value-key="id"
          size="mini"
          @change="selectobjectName()"
        >
          <el-option
            v-for="item in typeList"
            :key="item.objectAlias"
            :label="item.objectAlias"
            :value="item.objectAlias"
          ></el-option>
        </el-select>

        <span class="seach_text" style="margin-left:15px">是否配置:</span>
        <el-select
          v-model="custom"
          placeholder="类型"
          clearable
          value-key="id"
          size="mini"
          @change="selectobjectName()"
        >
          <el-option
            v-for="(item, index) in customList"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-button
          size="small"
          @click="addRoleResource()"
          type="primary"
          icon="el-icon-plus"
          v-access
          class="top_btn"
          data-ctrl="roleResourceAdd"
          style="margin-left:15px"
        >新建</el-button>
        <el-button
          size="small"
          @click="loadData()"
          type="primary"
          icon="el-icon-refresh"
          v-access
          class="top_btn"
          data-ctrl="roleResourceAdd"
        >刷新</el-button>
      </div>
    </div>

    <el-table :data="tableList">
      <el-table-column label="功能名称">
        <template slot-scope="scope">{{ scope.row.name }}</template>
      </el-table-column>

      <el-table-column prop="desc" label="描述">
        <template slot-scope="scope">{{ scope.row.desc }}</template>
      </el-table-column>
      <el-table-column prop="objectName" label="所属模块">
        <template slot-scope="scope">{{ scope.row.objectName }}</template>
      </el-table-column>
      <el-table-column prop="api" label="功能对应接口">
        <template slot-scope="scope">{{ scope.row.api }}</template>
      </el-table-column>
      <el-table-column prop="action" label="控制标识">
        <template slot-scope="scope">{{ scope.row.action }}</template>
      </el-table-column>

      <el-table-column prop="linkTimes" label="引用次数">
        <template slot-scope="scope">
          <el-button
            type="text"
            :disabled="scope.row.linkTimes == 0"
            @click="linkTimesShow(scope.row)"
          >{{ scope.row.linkTimes }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="enable" label="禁用">
        <template slot-scope="scope">
          <span v-if="scope.row.enable">未禁用</span>
          <span v-else>禁用</span>
        </template>
      </el-table-column>
      <el-table-column prop="custom" label="可配置">
        <template slot-scope="scope">
          <span v-if="scope.row.custom">可配置</span>
          <span v-else>不可配置</span>
        </template>
      </el-table-column>
      <el-table-column prop="common" label="公共组件">
        <template slot-scope="scope">
          <span v-if="scope.row.common">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            :disabled="scope.row.linkTimes > 0"
            type="text"
            size="small"
            v-access
            data-ctrl="roleResourceDel"
            @click="delRoleResource(scope.row.code)"
          >删除</el-button>
          <el-button
            type="text"
            size="small"
            v-access
            data-ctrl="roleResourceEdit"
            @click="editRoleResource(scope.row.code)"
          >编辑</el-button>
          <el-button
            type="text"
            size="small"
            v-access
            data-ctrl="roleResourceEdit"
            @click="copyRoleResource(scope.row.code)"
          >复制权限</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="dependentShow" width="500px" :before-close="handleClose">
      <div slot="title" class="left_top">
        <div class="left_icon">
          <span></span>
          <span>引用该资源的资源</span>
        </div>
      </div>
      <el-table :data="relyList" border style="width: 100%">
        <el-table-column prop="objectName" label="所属资源"></el-table-column>
        <el-table-column prop="name" label="资源名称"></el-table-column>
      </el-table>

      <!-- <span slot="footer"
            class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary"
                   @click="submit()">确 定</el-button>
      </span>-->
    </el-dialog>
    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin:0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
    <Add :dialogVisible.sync="addVisible" :code="code" :type="type" @close="loadData"></Add>
  </div>
</template>
<script>
import { api } from '/src/api/base';
export const roleResourceList = api('')('/roleResource.list.json');
export const delRoleResourceList = api('')('/roleResource.del.json');
export const roleResourceBaseObjects = api()('/roleResource.baseObjects.json');

export default {
  data () {
    return {
      title: '',
      focusCode: '',

      value: true,
      pageSize: 10,
      pageNumber: 1,
      relyList: [],
      dependentShow: false,
      custom: undefined,
      objectName: '',
      customList: [
        {
          value: true,
          label: '可配置'
        },
        {
          value: false,
          label: '不可配置'
        }
      ],
      keywords: '',
      total: 1000,
      tableList: [],
      code: '',
      addVisible: false,
      type: '',
      resourcesList: [],
      typeList: []
    };
  },
  components: {
    Add: () => import('./add.vue')
  },
  mounted () {
    this.loadData();
    this.getRoleResourceBaseObjects();
  },
  methods: {
    getRoleResourceBaseObjects () {
      roleResourceBaseObjects().then(res => {
        this.typeList = res;
      });
    },
    selectobjectName () {
      this.pageNumber = 1;
      this.loadData();
    },
    loadData () {
      const params = {
        keywords: this.keywords,
        custom: this.custom,
        objectName: this.objectName,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        $ignoreRepeat: true
      };
      roleResourceList(params).then(res => {
        this.tableList = res.list;
        this.total = res.total;
      });
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },
    close (formName) {
      this.$refs[formName].resetFields();
      this.closeDialog();
    },
    putAway () {
      Object.keys(this.formInline).forEach(it => {
        this.formInline[it] = '';
      });
      this.pageNumber = 1;
      this.loadData();
    },
    linkTimesShow (item) {
      const params = {
        pageSize: 1000,
        pageNumber: 1,
        find_in_set_desp: item.code
      };
      roleResourceList(params).then(res => {
        this.dependentShow = true;
        this.relyList = res.list;
      });
    },
    addRoleResource () {
      this.$router.push({
        path: '/system/resourceManagement/Add',
        query: {
          type: 'add'
        }
      });
    },
    async editRoleResource (code) {
      this.$router.push({
        path: '/system/resourceManagement/Add',
        query: {
          type: 'edit',
          code
        }
      });
    },
    copyRoleResource (code) {
      this.$router.push({
        path: '/system/resourceManagement/Add',
        query: {
          type: 'copy',
          code
        }
      });
    },
    handleClose () {
      this.dependentShow = false;
      this.loadData();
    },
    cancel () {
      this.dependentShow = false;
    },
    submit () {
      this.dependentShow = false;
    },
    delRoleResource (code) {

      this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const params = {
            code: code
          };
          delRoleResourceList(params).then(res => {

            if (res.msg === 'ok') {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.loadData();
            } else {
              // this.$message({
              //   type: "info",
              //   message: "请先解绑依赖权限"
              // });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    }
  }
};
</script>
<style lang="scss">
.summa {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
